<script setup lang="ts">
import AppLogo from "@/layouts/partials/marketing/AppLogo.vue";
</script>

<template>
  <div class="bg-decoration flex min-h-screen w-full items-center justify-center relative flex-col overflow-auto">
    <NuxtLink :to="ROUTES.home">
      <AppLogo class="mb-3 sm:absolute sm:top-8 left-8 sm:mb-0" />
    </NuxtLink>
    <div class="flex w-full justify-center items-center px-2 sm:max-w-4xl">
      <slot />
    </div>
  </div>
</template>
